import smoothscroll from 'smoothscroll-polyfill';
import debounce from './debounce.js';

// Get our Polyfill and initiate it.
smoothscroll.polyfill();

/**
 * Scroll to targeted coordinates
 * 
 * https://css-tricks.com/snippets/jquery/smooth-scrolling/
 * 
 * @param {number} y Y-axis coordinate to scroll to
 * @param {number} x X-axis coordinate to scroll to
 */

 const D3ScrollTo = debounce((y, x) => {
  window.scrollTo({
    top: y || 0,
    left: x || 0,
    behavior: 'smooth',
  });
 }, 250);

export default D3ScrollTo;