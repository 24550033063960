import SimpleBar from 'simplebar';
import Isotope from 'isotope-layout';
import './scroll-magic-config.js';
import MobileNav from './util/MobileNav';
import D3ScrollTo from './util/D3ScrollTo';

const toggleScrolledClass = (scrollPos, targetPos) => {
  if (scrollPos > targetPos) {
    document.body.classList.add('scrolled');
  } else {
    document.body.classList.remove('scrolled');
  }
}

document.addEventListener('DOMContentLoaded', e => {
  const mobileNav = new MobileNav({
    toggle: '.menu-toggle',
    nav: '#main-nav'
  });

  document.querySelectorAll('.simplebar').forEach( el => new SimpleBar(el));

  const scrollToTopBtn = document.getElementById('scroll-to-top');

  document.addEventListener('click', e => {
    if (e.target.matches('#scroll-to-content')) {
      D3ScrollTo(e.target.parentElement.offsetHeight);
    }

    if (e.target.matches('#scroll-to-top')) {
      D3ScrollTo(0);
    }
  });

  let scrollPos = window.pageYOffset;
  toggleScrolledClass(scrollPos, 50);

  window.addEventListener('scroll', e => {
    let scrollPos = window.pageYOffset;
    toggleScrolledClass(scrollPos, 50);
  });

  const groceryList = document.querySelector('.grocery-list');
  if (groceryList) {
    const groceryIso = new Isotope(groceryList);
  }
});

(function($) {
  $(document).ready(function() {

    $('a[data-rel^=lightcase]').lightcase({
      showSequenceInfo: false
    });

    // REVIEWS SLIDER -----------------------------------------------------
    $('.reviews--slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      infinite: true,
      prevArrow: '<button class="slick-prev"><i class="fas fa-long-arrow-alt-left"></i><span class="sr-only">Previous</span></button>',
      nextArrow: '<button class="slick-next"><i class="fas fa-long-arrow-alt-right"></i><span class="sr-only">Next</span></button>',
      centerMode: true,
      centerPadding: '26.7%',
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerPadding: '15%',
          }
        },
        {
          breakpoint: 991,
          settings: {
            centerPadding: '10%',
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
          }
        }
      ],
    });

    $('.gallery--slider').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      draggable: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });

    $('.gallery--slider--alt').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });

    $('.gallery--slider--single-img').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true
    });

    $('.date-field--past').flatpickr({
      dateFormat: 'F j, Y',
      maxDate: 'today',
    })

    $('.date-field').flatpickr({
      dateFormat: 'F j, Y'
    });

    $('.date-field--future').flatpickr({
      dateFormat: 'F j, Y',
      minDate: 'today',
    });

    $('[data-toggle="tooltip"]').tooltip();

    jQuery('select[name="event_view"]').on('change', function() {
      switch(jQuery(this).val()) {
        case 'month':
          jQuery('.views > div').hide();
            jQuery('.views .month').show();
            break;
        case 'list':
          jQuery('.views > div').hide();
          jQuery('.views .list').show();
            break;
        case 'day':
          jQuery('.views > div').hide();
          jQuery('.views .daily').show();
      } 
    });
  });

  $(window).on('load', function(){
    $('noscript').attr('aria-hidden', 'true');
  });

  var booking = $('#custom_engine');
  if (booking.length) {
    var bookingOffset = booking.offset().top;
  }
  var responsive_viewport = $(window).width(); 
  $(window).on('scroll', function() {  
    var scroll = $(window).scrollTop();
    var headerHeight = $('.region--header__inner').outerHeight();
    if (responsive_viewport >= 768) {
      if (scroll >= (bookingOffset - headerHeight)) {
        booking.addClass('fixed');
        booking.css('padding-top', headerHeight);
        $('#main').css('padding-top', headerHeight + 'px');
      } else {
        booking.removeClass('fixed');
        booking.css('padding-top', '0px');
        $('#main').css('padding-top', '0px');
      }
    }
  });

})(jQuery);