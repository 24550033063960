export default class MobileNav {
  constructor(options = {}) {
    Object.assign(this.options = {}, options);

    this.el = {};

    this.open = false;

    this.el.toggle = document.querySelector(this.options.toggle);
    this.el.nav = document.querySelector(this.options.nav);

    this.el.toggle.addEventListener('mousedown', this.handleToggle.bind(this));

    this.el.nav.addEventListener('mousedown', this.handleMenuItems.bind(this));
    
    this.topMenu = this.el.nav.querySelector('ul');
    this.setCurrentMenu(this.topMenu);
  }

  handleToggle(e) {
    if (e.which != 1) return false;

    this.open ? this.closeNav() : this.openNav();
  }

  openNav() {
    this.open = true;
    this.disablePageScroll();
    document.body.classList.toggle('mobile-visible');
  }

  closeNav() {
    this.open = false;
    this.enablePageScroll();
    document.body.classList.remove('mobile-visible');
    this.el.nav.querySelectorAll('ul').forEach(ul => ul.classList.remove('active'));
    this.setCurrentMenu(this.topMenu);
  }

  handleMenuItems(e) {
    let target = e.target;
    let parentLi = target.parentElement;
    let parentUl = parentLi.parentElement;
    let subMenu = parentLi.querySelector('ul');

    if (target.matches('.menu-item__toggle-sub') && e.which === 1) {
      if (subMenu) {
        this.setCurrentMenu(subMenu);
        e.preventDefault();
      }
    }

    if (target.matches('.prevmenu') && e.which === 1) {
      let parentMenu = this.findAncestor(parentUl, 'ul');
      this.setCurrentMenu(parentMenu);
    }
  }

  setCurrentMenu(menu) {
    if (this.currentMenu) this.currentMenu.classList.remove('active');
    this.currentMenu = menu;
    this.currentMenu.classList.add('active');
  }

  findAncestor(el, selector) {
    while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el, selector)));
    return el;
  }

  disablePageScroll() {
    const body = document.body;
    this.windowScrollY = window.scrollY;
    body.style.position = 'fixed';
    body.style.overflowY = 'hidden';
    body.style.top = `-${this.windowScrollY}px`;
  }

  enablePageScroll() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}