document.addEventListener('DOMContentLoaded', e => {
  const scrollMagicController = new ScrollMagic.Controller();
  document.querySelectorAll('.parallax').forEach(el => {
    new ScrollMagic.Scene({ 
      triggerElement: el,
      duration: '200%',
    })
      .setTween(el.firstElementChild, { y: '80%', ease: Linear.easeNone})
      .addTo(scrollMagicController);
  });

  document.querySelectorAll('.slide-in').forEach((el, index) => {
    const tweenOptions = {
      autoAlpha: 0,
      ease: Back.easeOut,
    };

    0 == index % 2 ? tweenOptions.left = '-100%' : tweenOptions.right = '-100%';

    new ScrollMagic.Scene({
      triggerElement: el,
      triggerHook: 0.5,
      offset: -100,
    })
      .setTween(TweenMax.from(el, 1, tweenOptions))
      .addTo(scrollMagicController);
  });
});